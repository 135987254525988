//TODO: makes other menu column templates redundant
const columns = [
  {
    field: "Image",
    header: "",
    default: [],
    restrict: [],
  },
  {
    field: "Tags",
    header: "Tags",
    default: [],
    restrict: [],
  },
  {
    field: "ProductName",
    header: "name",
    default: ["Flower", "Joint", "Edible", "Extract", "Vape"],
    restrict: [],
  },
  {
    field: "Type",
    header: "product type",
    default: [],
    restrict: [],
  },
  {
    field: "Strain",
    header: "strain",
    default: [],
    restrict: [],
  },
  {
    field: "Dominance",
    header: "type",
    default: ["Flower"],
    restrict: [],
  },
  {
    field: "Thc",
    header: "THC",
    default: ["Flower", "Joint", "Edible", "Extract", "Vape"],
    restrict: [],
  },
  {
    field: "TotalCannabinoids",
    header: "Cannabinoids",
    default: [],
    restrict: [],
  },
  {
    field: "TotalTerpenes",
    header: "Terpenes",
    default: [],
    restrict: [],
  },
  {
    field: "Tac",
    header: "TAC",
    default: [],
    restrict: [],
  },
  {
    field: "Thca",
    header: "THCA",
    default: [],
    restrict: [],
  },
  {
    field: "Cbd",
    header: "CBD",
    default: [],
    restrict: [],
  },
  {
    field: "Serving",
    header: "serving",
    default: [],
    restrict: [],
  },
  {
    field: "Quantity",
    header: "quantity",
    default: [],
    restrict: [],
  },
  {
    field: "Weight",
    header: "weight",
    default: ["Extract"],
    restrict: [],
  },
  {
    field: "Price",
    header: "price",
    default: ["Joint", "Edible", "Extract", "Vape"],
    restrict: [],
  },
  {
    field: "PriceHalfGram",
    header: ".5G",
    default: [],
    restrict: [],
  },
  {
    field: "PriceGram",
    header: "G",
    default: ["Flower"],
    restrict: ["Joint", "Edible", "Extract", "Vape"],
  },
  {
    field: "PriceTwoGram",
    header: "2G",
    default: [],
    restrict: ["Joint", "Edible", "Extract", "Vape"],
  },
  {
    field: "PriceEighth",
    header: "1/8",
    default: ["Flower"],
    restrict: ["Joint", "Edible", "Extract", "Vape"],
  },
  {
    field: "PriceQuarter",
    header: "1/4",
    default: ["Flower"],
    restrict: ["Joint", "Edible", "Extract", "Vape"],
  },
  {
    field: "PriceHalfOunce",
    header: "1/2",
    default: ["Flower"],
    restrict: ["Joint", "Edible", "Extract", "Vape"],
  },
  {
    field: "PriceOunce",
    header: "OZ",
    default: ["Flower"],
    restrict: ["Joint", "Edible", "Extract", "Vape"],
  },
  {
    field: "PriceHalfEighth",
    header: "1/16",
    default: [],
    restrict: ["Joint", "Edible", "Extract", "Vape"],
  },
  {
    field: "Brand",
    header: "brand",
    default: ["Edible", "Extract", "Vape"],
    restrict: [],
  },
  {
    field: "custom",
    header: "Price Tier",
    default: [],
    restrict: [
      "Flower",
      "Edible",
      "Joint",
      "Pre-Rolls",
      "Vape",
      "Extract",
      "Concentrates",
    ],
  },
];

export const parseableColumns = ['1/8', '1/4', '1/2', 'OZ', '1/16'];

export const convertColumnHeaderIncrements = (value) => {
  switch(value){
    case "1/8": return '3.5G';
    case "1/4": return '7G';
    case "1/2": return '14G';
    case "1/16": return '1.7G';
    case "OZ": return '28G';
    default: return ''
  }
}

export default columns;
